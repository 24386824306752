<template>
  <div class="container">
    <div class="video-box">
      <div class="titles">
        <ul>
          <div v-for="(sectionVideos, key, index) in videos" :key="index">
            <h4>{{ key }}</h4>
            <li
              :class="index === videoIndex && key === videoSection ? 'active' : ''"
              v-for="(video, index) in sectionVideos"
              :key="video.id"
              @click="setVideo(key, index)"
            >
              {{ video.title }}
            </li>
            <hr />
          </div>
        </ul>
      </div>
      <div class="video">
        <div>
          <h3>
            {{ videoView.title }}
          </h3>
          <hr />
        </div>
        <div class="video-player">
          <iframe
            style="width: 100%;height: 100%;"
            :src="videoView.url_link"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TOEFL from "@/apis/toeflVideos";

export default {
  metaInfo() {
    return {
      title: "Toefl Videos - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      videos: [],
      videoSection: "Reading",
      videoIndex: 0
    };
  },
  computed: {
    videoView() {
      return this.videos[this.videoSection][this.videoIndex];
    }
  },
  watch: {},

  mounted() {
    this.getVideos();
  },

  methods: {
    async getVideos() {
      const res = await TOEFL.getVideos();
      this.videos = res.course_record.course_videos;
    },
    setVideo(section, index) {
      this.videoSection = section;
      this.videoIndex = index;
    }
  }
};
</script>

<style scoped>
.container {
  padding-top: 20px;
}
.videos-box {
  height: 100%;
}
.video-box {
  display: flex;
  background-color: #efefef;
  margin-bottom: 20px;
}
.titles {
  height: calc(100vh - 180px);
  width: 400px;
  overflow: auto;
}
ul {
  list-style: none;
}
ul li:hover {
  color: #42a16a;
}
ul li {
  /* height:40px; */
  line-height: 40px;
  cursor: pointer;
}
ul li.active {
  font-weight: 700;
  color: #42a16a;
}
.titles {
  padding: 20px 10px;
}
.video {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-left: 1px solid #ccc;
}
.video-player {
  flex: 1;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
</style>
